var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.project.embedded.name")) + " "
              )
            ]),
            _c("hr"),
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.project.embedded.description")) +
                  " "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.project.embedded.iframeLinkLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "div",
                { staticClass: "position-relative" },
                [
                  _c("b-icon", {
                    staticClass: "position-absolute",
                    attrs: { icon: "files", "aria-hidden": "true" },
                    on: {
                      click: function($event) {
                        return _vm.copyText("iframeLink")
                      }
                    }
                  }),
                  _c("b-form-textarea", {
                    attrs: { id: "iframe-link", rows: "1", disabled: "" },
                    model: {
                      value: _vm.iframeLink,
                      callback: function($$v) {
                        _vm.iframeLink = $$v
                      },
                      expression: "iframeLink"
                    }
                  })
                ],
                1
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settings.project.embedded.iframeLinkDesc")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.project.embedded.create")) + " "
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.embedded.webmagLinkTextLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "embed-webmag-link-text", debounce: "500" },
                model: {
                  value: _vm.webmagLinkText,
                  callback: function($$v) {
                    _vm.webmagLinkText = $$v
                  },
                  expression: "webmagLinkText"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.project.embedded.webmagLinkTextDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.embedded.webmagLinkAnimationLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-select", {
                attrs: {
                  id: "embed-webmag-display-mode",
                  options: _vm.webmagLinkAnimationOptions
                },
                model: {
                  value: _vm.webmagLinkAnimation,
                  callback: function($$v) {
                    _vm.webmagLinkAnimation = $$v
                  },
                  expression: "webmagLinkAnimation"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.project.embedded.webmagColorLabel")) +
                  " "
              )
            ])
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "7" } }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("color-picker", {
                  attrs: { color: _vm.webmagColor },
                  on: { "update-color": _vm.updateWebmagColor }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.embedded.webmagDisplayModeLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-select", {
                attrs: {
                  id: "embed-webmag-display-mode",
                  options: _vm.displayModeOptions
                },
                model: {
                  value: _vm.webmagDisplayMode,
                  callback: function($$v) {
                    _vm.webmagDisplayMode = $$v
                  },
                  expression: "webmagDisplayMode"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "settings.project.embedded.webmagHoverAnimationLabel"
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-select", {
                attrs: {
                  id: "embed-webmag-hover-animation",
                  options: _vm.webmagHoverAnimationOptions
                },
                model: {
                  value: _vm.webmagHoverAnimation,
                  callback: function($$v) {
                    _vm.webmagHoverAnimation = $$v
                  },
                  expression: "webmagHoverAnimation"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.embedded.webmagEmbedWidthLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "embed-webmag-embed-width", debounce: "500" },
                model: {
                  value: _vm.webmagEmbedWidth,
                  callback: function($$v) {
                    _vm.webmagEmbedWidth = $$v
                  },
                  expression: "webmagEmbedWidth"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.project.embedded.webmagEmbedWidthDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", { staticClass: "mt-2 mb-0" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("settings.project.embedded.webmagMaxModalWidthLabel")
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "embed-webmag-max-modal-width", debounce: "500" },
                model: {
                  value: _vm.webmagMaxModalWidth,
                  callback: function($$v) {
                    _vm.webmagMaxModalWidth = $$v
                  },
                  expression: "webmagMaxModalWidth"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.project.embedded.webmagMaxModalWidthDesc"
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: { name: "use-screenshot-checkbox", switch: "" },
                  on: { change: _vm.handleUseScreenshotChange },
                  model: {
                    value: _vm.useScreenshot,
                    callback: function($$v) {
                      _vm.useScreenshot = $$v
                    },
                    expression: "useScreenshot"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("settings.project.embedded.useScreenshotLabel")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.useScreenshot
        ? [
            _c(
              "b-row",
              { staticClass: "mb-4" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                  [
                    _c("label", { staticClass: "mt-2 mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "settings.project.embedded.webmagScreenshotUrlLabel"
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "7" } },
                  [
                    _c("image-upload", {
                      attrs: { "image-object": _vm.screenshotImageObject },
                      on: {
                        "upload-image-object": _vm.updateScreenshotImageObject
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : [
            _c(
              "b-row",
              { staticClass: "mb-4" },
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", "offset-sm": "1", sm: "3" } },
                  [
                    _c("label", { staticClass: "mt-2 mb-0" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "settings.project.embedded.webmagEmbedHeightLabel"
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", sm: "7" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "embed-webmag-embed-height",
                        debounce: "500"
                      },
                      model: {
                        value: _vm.webmagEmbedHeight,
                        callback: function($$v) {
                          _vm.webmagEmbedHeight = $$v
                        },
                        expression: "webmagEmbedHeight"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t("settings.project.embedded.previewLabel")))
            ]),
            _c("div", {
              staticClass: "embed-preview",
              domProps: { innerHTML: _vm._s(_vm.dynamicCode) }
            })
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.project.embedded.dynamicCodeLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "div",
                { staticClass: "position-relative" },
                [
                  _c("b-icon", {
                    staticClass: "position-absolute",
                    attrs: { icon: "files", "aria-hidden": "true" },
                    on: {
                      click: function($event) {
                        return _vm.copyText("dynamicCode")
                      }
                    }
                  }),
                  _c("b-form-textarea", {
                    attrs: { id: "dynamic-code", rows: "10", disabled: "" },
                    model: {
                      value: _vm.dynamicCode,
                      callback: function($$v) {
                        _vm.dynamicCode = $$v
                      },
                      expression: "dynamicCode"
                    }
                  })
                ],
                1
              ),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.project.embedded.dynamicCodeDesc")
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }