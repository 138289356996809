import gql from 'graphql-tag';

export default gql`
query getAllGroupsOfUserWithAtLeastManager($uid: Int!) {
  users_roles_groups(
    where: {_and:
      {
        user_id: {_eq: $uid},
        role: { level: {_lte: 2} }
      }
    }) {
    role {
      handle
    }
    group {
      group_id
      group_path
      name
      domain
      parent_group_id
      settings
      slug
      pirsch_code
    }
  }
}
`;
