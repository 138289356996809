var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-container", [
    _vm.hasAccessToGroupManagement
      ? _c(
          "div",
          [
            _vm.userObject && _vm.groups !== null
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "tabs",
                        attrs: { "col-auto": "", cols: "3" }
                      },
                      [
                        _vm.groups.length === 0
                          ? _c("div", { staticClass: "mt-5" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("groupManagement.howToGroupDesc")
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._l(_vm.groups, function(group, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  class: index === 0 ? "mt-5 " : ""
                                },
                                [
                                  _c(
                                    "ul",
                                    {
                                      key: _vm.keySelect,
                                      staticClass: "customIndent"
                                    },
                                    [
                                      _c("TreeItem", {
                                        key: group.group_id,
                                        staticClass: "item",
                                        attrs: {
                                          model: group,
                                          selectedGroupId:
                                            _vm.selectedGroup.group_id,
                                          selectedGroupPath:
                                            _vm.selectedGroup.group_path
                                        },
                                        on: { selected: _vm.selectGroup }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                      ],
                      2
                    ),
                    _c(
                      "b-col",
                      { staticClass: "tab-content pb-5" },
                      [
                        _vm.groups.length !== 0
                          ? _c("group-list-view", {
                              staticClass: "mt-5 pr-5",
                              attrs: {
                                id: "group-list-view",
                                isSysAdmin: _vm.userObject.isSysAdmin,
                                "current-user-id": _vm.userObject.userId,
                                "current-user-email": _vm.userObject.email,
                                userPrivileges: _vm.currentGroupPrivileges,
                                group: _vm.selectedGroup
                              },
                              on: {
                                createGroup: _vm.createGroup,
                                deleteGroup: _vm.deleteGroup,
                                updateCustomDomain: _vm.updateCustomDomain,
                                saveCustomDomain: _vm.saveCustomDomain,
                                selected: _vm.selectGroup
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _c("div", { staticClass: "pt-5" }, [
          !_vm.$apollo.loading
            ? _c("div", [
                _c("p", {
                  staticClass: "text-center",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("groupManagement.notAllowed"))
                  }
                })
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }