<template>
  <b-container>
    <div v-if="hasAccessToGroupManagement">
      <b-row v-if="userObject && groups !== null">
        <!-- Tree view of all groups the current user has administrator privileges on -->
        <b-col class="tabs" col-auto cols="3">
          <div v-if="groups.length === 0" class="mt-5 ">
            {{ $t('groupManagement.howToGroupDesc') }}
          </div>
          <div v-else v-for="(group, index) in groups" :key="index"
               :class="(index === 0) ? 'mt-5 ' : ''">
            <ul class="customIndent" :key="keySelect">
              <TreeItem
                class="item"
                :model="group"
                :selectedGroupId="selectedGroup.group_id"
                :selectedGroupPath="selectedGroup.group_path"
                @selected="selectGroup"
                :key="group.group_id">
              </TreeItem>
            </ul>
          </div>
        </b-col>

        <!-- Page view of selected group -->
        <b-col class="tab-content pb-5">
          <group-list-view
            id="group-list-view"
            v-if="groups.length !== 0"
            class="mt-5 pr-5"
            :isSysAdmin="userObject.isSysAdmin"
            :current-user-id="userObject.userId"
            :current-user-email="userObject.email"
            :userPrivileges="currentGroupPrivileges"
            :group="selectedGroup"
            @createGroup="createGroup"
            @deleteGroup="deleteGroup"
            @updateCustomDomain="updateCustomDomain"
            @saveCustomDomain="saveCustomDomain"
            @selected="selectGroup"
          ></group-list-view>
        </b-col>
      </b-row>
    </div>
    <div v-else class="pt-5">
      <div v-if="!$apollo.loading">
        <p class="text-center" v-html="$t('groupManagement.notAllowed')"></p>
      </div>
    </div>
  </b-container>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import SetRunBreadcrumbQueryInCache from '@/graphQlQueries/mutations/setRunBreadcrumbQueryInCache';
import SetShowHeaderInCache from '@/graphQlQueries/mutations/setShowHeaderInCache';
import UserAtLeastManagerInOneGroup from '@/graphQlQueries/queries/accessCheckForManagementAreas';
import GetAllGroups from '@/graphQlQueries/queries/getAllGroups';

import GetAllGroupsOfUser from '@/graphQlQueries/queries/getAllGroupsOfUser';
import UserDataAndAccess from '@/mixins/userDataAndAccess';

export default {
  name: 'Group',
  components: {
    TreeItem: () => import('@/components/settings/groups/TreeItem.vue'),
    GroupListView: () => import('@/components/settings/groups/GroupListView.vue'),
  },
  mixins: [UserDataAndAccess],
  data: () => ({
    pageType: 'group-settings',
    groups: null,
    number: 342,
    selectedGroup: null,
    lastSelectedGroup: null,
    hasAccessToGroupManagement: false,
    defaultGroupSettings: {
      showMedia: false,
      showTemplateItems: false,
      customDomain: '',
      enableCustomFonts: false,
      customFonts: [],
    },
    keySelect: 42,
  }),
  created() {
    this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
    this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: 'group-settings',
      },
    });
    this.$apollo.mutate({
      mutation: SetRunBreadcrumbQueryInCache,
      variables: {
        runQuery: true,
      },
    });
    this.$apollo.mutate({
      mutation: SetShowHeaderInCache,
      variables: {
        show: true,
      },
    });
  },
  apollo: {
    hasAccessToGroupManagement: {
      query: UserAtLeastManagerInOneGroup,
      update(data) {
        if (data.checkAccessToManagementAreas === false) {
          this.$apollo.mutate({
            mutation: SetLoadingIndicator,
            variables: {
              isIndicatorLoading: false,
            },
          });
        }
        return data.checkAccessToManagementAreas;
      },
    },
    groups: {
      variables() {
        if (this.userObject.isSysAdmin) {
          return null;
        }
        return {
          uid: this.userObject.userId,
        };
      },
      query() {
        if (this.userObject.isSysAdmin) {
          return GetAllGroups;
        }
        return GetAllGroupsOfUser;
      },
      update(data) {
        // const groupObjects = [];
        const groupMemberArray = [];
        const groupsData = [];

        if (data.users_roles_groups) {
          data.users_roles_groups.forEach((userRolesGroups) => {
            // get the hierarchical ids from the group path to create the group tree
            const groupPathArray = userRolesGroups.group.group_path.split('.').map(Number);
            groupMemberArray.push(groupPathArray);
            groupsData.push({
              user_role: userRolesGroups.role.handle,
              ...userRolesGroups.group,
            });
          });
        } else if (data.groups) {
          data.groups.forEach((group) => {
            // get the hierarchical ids from the group path to create the group tree
            const groupPathArray = group.group_path.split('.').map(Number);
            groupMemberArray.push(groupPathArray);
            groupsData.push({
              user_role: 'sysAdmin',
              ...group,
            });
          });
        } else {
          return null;
        }

        let tree = [];
        groupMemberArray.forEach((groupMemberItem) => {
          const insertGroupTree = [this.buildTree(groupMemberItem)];
          tree = this.insertIntoSubtree(tree, insertGroupTree);
        });
        // remove the branches the user is not member of
        const cleanedTree = this.cleanTheTree(tree);
        // add all the group data to the groups in the cleaned tree
        const completeTree = this.populateTheGroupMembersWithData(cleanedTree, groupsData);
        const sortedTree = this.sortTheTreeAlphabetical(completeTree);
        // update the lastSelectedGroup to stay updated with the subgroup table
        if (this.lastSelectedGroup) {
          this.lastSelectedGroup = this.searchNodeInGroupTree(
            sortedTree,
            this.lastSelectedGroup.group_id,
          );
        }
        // Keep the last known selected group when query refreshes
        this.selectedGroup = (this.lastSelectedGroup) ? this.lastSelectedGroup : sortedTree[0];

        if (this.userObject.isSysAdmin) {
          this.$currentUserRole = 'admin';
        } else {
          this.$currentUserRole = this.selectedGroup.user_role;
        }
        return sortedTree;
      },
      skip() {
        return !this.userObject.userId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    searchNodeInGroupTree(tree, groupId) {
      let group = null;
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].group_id === groupId) {
          return tree[i];
        }
        if (tree[i].children) {
          group = this.searchNodeInGroupTree(tree[i].children, groupId);
          if (group) {
            break;
          }
        }
      }
      return group;
    },
    sortTheTreeAlphabetical(tree) {
      const treeArray = [...tree];
      treeArray.forEach((treeItem, index) => {
        if (treeItem.children) {
          treeArray[index].children = this.sortTheTreeAlphabetical(treeItem.children);
        }
      });
      treeArray.sort((a, b) => (
        // eslint-disable-next-line no-nested-ternary
        (a.name.trim().toLowerCase() > b.name.trim().toLowerCase())
          ? 1
          : ((b.name.trim().toLowerCase() > a.name.trim().toLowerCase())
            ? -1
            : 0
          )
      ));
      return treeArray;
    },
    populateTheGroupMembersWithData(items, groups) {
      const populatedTree = [...items];
      items.forEach((item, index) => {
        if (item.member) {
          // search for the group element
          const group = groups.find((groupItem) => groupItem.group_id === item.group_id);
          if (group) {
            group.settings = { ...this.defaultGroupSettings, ...group.settings };
            populatedTree[index] = { ...populatedTree[index], ...group };
          }
          if (item.children) {
            populatedTree[index].children = this.populateTheGroupMembersWithData(
              item.children, groups,
            );
          }
        }
      });
      return populatedTree;
    },
    cleanTheTree(groupItemArray) {
      let groupItems = [];
      groupItemArray.forEach((groupItem) => {
        const groupItemElement = groupItem;
        if (groupItemElement.children !== null) {
          const children = this.cleanTheTree(groupItemElement.children);
          if (children.length) {
            // now check if the current group is a member or not
            if (groupItemElement.member) {
              groupItemElement.children = children;
              groupItems.push(groupItemElement);
            } else {
              groupItems = [...groupItems, ...children];
            }
          }
        } else if (groupItemElement.member) {
          groupItems.push(groupItemElement);
        }
      });
      return groupItems;
    },
    insertIntoSubtree(tree, insertGroupTree) {
      const theTree = [...tree];
      if (!tree.length) {
        return insertGroupTree;
      }
      insertGroupTree.forEach((insertGroupTreeTopLevel) => {
        const insertGroupId = insertGroupTreeTopLevel.group_id;
        // check if the insertGroupId is already in the tree
        const groupMatchIndex = theTree.findIndex((leaf) => leaf.group_id === insertGroupId);
        if (groupMatchIndex !== -1) {
          if (insertGroupTreeTopLevel.member) {
            theTree[groupMatchIndex].member = true;
          }
          if (theTree[groupMatchIndex].children === null) {
            // if the insert tree has no children we don't have to do something
            if (insertGroupTreeTopLevel.children !== null) {
              theTree[groupMatchIndex].children = insertGroupTreeTopLevel.children;
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (insertGroupTreeTopLevel.children) {
              if (theTree[groupMatchIndex].children) {
                theTree[groupMatchIndex].children = this.insertIntoSubtree(
                  theTree[groupMatchIndex].children,
                  insertGroupTreeTopLevel.children,
                );
              } else {
                theTree[groupMatchIndex].children = [...insertGroupTreeTopLevel.children];
              }
            }
          }
        } else {
          theTree.push(insertGroupTreeTopLevel);
        }
      });
      return theTree;
    },
    buildTree(groupPath) {
      if (groupPath.length <= 1) {
        return { group_id: groupPath[0], member: true, children: null };
      }
      const childGroupPath = [...groupPath];
      const group_id = childGroupPath.shift();
      return { group_id, children: [this.buildTree(childGroupPath)] };
    },
    async selectGroup(group) {
      this.selectedGroup = group;
      if (this.userObject.isSysAdmin) {
        this.$currentUserRole = 'admin';
      } else {
        this.$currentUserRole = this.selectedGroup.user_role;
      }
      this.lastSelectedGroup = group;
      this.keySelect += 1;
      document.getElementById('group-list-view').scrollTop = 0;
    },
    async createGroup() {
      try {
        await this.$apollo.queries.groups.refetch();
      } catch (ex) {
        console.error(ex);
      }
    },
    async deleteGroup() {
      try {
        await this.$apollo.queries.groups.refetch();
      } catch (ex) {
        console.error(ex);
      }
    },
    updateCustomDomain(group) {
      this.selectedGroup.settings = group.settings;
    },
    saveCustomDomain(settings, customDomain) {
      this.selectedGroup.settings = settings;
      this.selectedGroup.domain = customDomain;
    },
  },
};
</script>

<style scoped>
.tabs, .tab-content.col {
  height: calc(100vh - 65px);
  overflow: auto;
}

.item {
  cursor: pointer;
  line-height: 1.5;
}

.bold {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

.customIndent {
  margin-left: -20px;
}
</style>
